import { Flex } from '../UI/Flex/Flex';
import loading from './loading.svg';

export const Loading = () => {
    return (
        <Flex
            fillCell={true}
            justify="start"
            alignItems="center"
            style={{ backgroundColor: 'white' }}
            direction="column"
        >
            <div
                style={{
                    color: '#243A5C',
                    fontSize: '60px',
                    fontFamily: 'LustTextMedium',
                    marginTop: '200px',
                    zIndex: 100
                }}
            >
                Loading
            </div>
            <div
                style={{
                    width: '200px',
                    transform: 'translateY(-30px)',
                    zIndex: 99
                }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <rect fill="#FFF" width="100%" height="100%" />
                    <rect
                        fill="#243A5C"
                        stroke="#243A5C"
                        stroke-width="14"
                        width="30"
                        height="30"
                        x="25"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.4"
                        ></animate>
                    </rect>
                    <rect
                        fill="#243A5C"
                        stroke="#243A5C"
                        stroke-width="14"
                        width="30"
                        height="30"
                        x="85"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="-.2"
                        ></animate>
                    </rect>
                    <rect
                        fill="#243A5C"
                        stroke="#243A5C"
                        stroke-width="14"
                        width="30"
                        height="30"
                        x="145"
                        y="50"
                    >
                        <animate
                            attributeName="y"
                            calcMode="spline"
                            dur="2"
                            values="50;120;50;"
                            keySplines=".5 0 .5 1;.5 0 .5 1"
                            repeatCount="indefinite"
                            begin="0"
                        ></animate>
                    </rect>
                </svg>
            </div>
        </Flex>
    );
};
