import { Backend } from '@assets/backend/backend';
import { DataAccessUtils } from '@assets/component/Financial/DataAccessUtils';
import { assert } from 'console';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import {
    action,
    autorun,
    computed,
    has,
    observable,
    runInAction,
    values
} from 'mobx';
import moment from 'moment';
import { FieldsSchema } from './FieldsSchema';
import _ from 'lodash';
import { OBSERVABLE } from 'mobx/dist/internal';
import { useEffect } from 'react';

function isString(s) {
    return typeof s === 'string' || s instanceof String;
}

interface StockInfo {
    ticker: string;
    current: number;
    eps: number;
    avg_over_year: number;
}

interface TranscriptEntry {
    highlight: string;
    source_text: string;
}
interface KPI {
    kpi: string;
    value: string;
}

interface YearlyFinance {
    cogs: string;
    ebitda: string;
    revenue: string;
    year: string;
    ebitda_margin: string;
    yoy_growth: string;
}
interface KeyPerson {
    name: string;
    position: string;
    started_job_year: string;
    bio: string;
}

interface Segment {
    revenue: string;
    service: string;
    segment: string;
    share: string;
    short_description: string;
}

interface ActiveJob {
    jobId: string;
    fileName: string;
    status: string;
    task: string;
    progress: number;
}

interface ModalDisplay {
    title: string;
    transcript: TranscriptEntry;
}

type ImageLookup = { [key: number]: string };

const list = [];
function preloadImages(array) {
    for (var i = 0; i < array.length; i++) {
        var img = new Image();
        img.onload = function () {
            var index = list.indexOf(this);
            if (index !== -1) {
                // remove image from the array once it's loaded
                // for memory consumption reasons
                list.splice(index, 1);
            }
        };
        list.push(img);
        img.src = array[i];
    }
}

class Store {
    constructor() {
        // autorun(async () => {
        //     if (!this.activeReport) {
        //         return null;
        //     }
        //     var el = document.getElementById('rootContainer');
        //     el.style.width = '2px';
        //     const baseURL = 'pdf_images/';
        //     const companyName = this.selectedTickerId;
        //     const storage = getStorage();
        //     // `${baseURL}${companyName}/hd_page-${this.pdfImagePage}.png`
        //     //get all the images used in sources
        //     var data = this.activeReport;
        //     var max_pages = this.activeReport.total_pdf_pages;
        //     var pagesToFetch = { '1': true };
        //     for (var i = 1; i <= max_pages; i++) {
        //         pagesToFetch[i] = true;
        //     }
        //     if (this.imageLookup.hasOwnProperty(companyName)) {
        //         return;
        //     }
        //     this.imageLookup[companyName] = {};
        //     // Object.keys(data).forEach(key => {
        //     //     if (key.endsWith('_page_source')) {
        //     //         const value = data[key];
        //     //         const pages = get_numbers(value);
        //     //         pages.forEach(page => {
        //     //             pagesToFetch[page] = true;
        //     //         });
        //     //     }
        //     // });
        //     var current = this.imageLookup[companyName];
        //     for (var page in pagesToFetch) {
        //         const pathReference = ref(
        //             storage,
        //             `gs://cim-project-7b81c.firebasestorage.app/pdfs/${companyName}/hd_page-${page}.png`
        //         );
        //         let currPage = parseInt(page);
        //         getDownloadURL(pathReference).then(url => {
        //             current[currPage] = url;
        //             preloadImages([url]);
        //         });
        //     }
        // });

        autorun(() => {
            if (!this.query) {
                return null;
            }

            var el = document.getElementById('rootContainer');
            el.style.width = '2px';

            const storage = getStorage();
            var file = this.query.value('pdf');
            const pathReference = ref(
                storage,
                `gs://cim-project-7b81c.firebasestorage.app/pdfs/${file}`
            );

            //this.pdfFileUrl = '';

            getDownloadURL(pathReference).then(url => {
                runInAction(() => {
                    this.pdfFileUrl = url;
                });
            });
        });
    }

    @observable accessor defaultContentSize = 50;

    @observable accessor lastReportImagesFetched: string = '';
    @observable accessor imageLookup: { [company: string]: ImageLookup } = {};

    @observable
    accessor modal: ModalDisplay = null;

    @observable
    accessor updatedStockInfo: StockInfo = null;

    @observable
    accessor waitingForTickerToFinish: string = '';

    @observable accessor dealPageNameSearch: string = '';
    @observable accessor dealPageIndustrySearch: string[] = [];
    @observable accessor dealPageScoreSearch: string[] = [];

    @observable
    accessor uiDisplayReportId: string = '';

    @observable
    accessor reportDataDB: any = null;

    @observable
    accessor settings: any = {};

    @observable accessor dataAccessTable: { [id: string]: DataAccessUtils } =
        {};

    @computed get showExportButton() {
        return this.settings?.showExportButton ?? false;
    }

    @computed get showDeleteButton() {
        return this.settings?.showDeleteButton ?? false;
    }
    @observable
    accessor reportDataOverridesDB: any = {};

    @observable
    accessor jobs: any = {};

    @observable
    accessor systemStatus: any = {};

    @observable accessor activeJob: ActiveJob = null;

    @observable accessor areOveridesLoaded: boolean = false;

    @computed get queueJobs() {
        return [];
        if (!this.jobs) {
            return [];
        }
        var activeJob = this.activeJob;

        var jobs = values(this.jobs);
        jobs = jobs.filter((job: any) => {
            return job.status == 'pending';
        });

        jobs = jobs.toSorted((a: any, b: any) => {
            return a.createdAt - b.createdAt;
        });

        return jobs;
    }

    @observable
    accessor pdfImagePage: number = -1;

    @observable accessor pdfFileUrl: string = '';

    @computed get processingJobName() {
        if (this.activeJob?.status != 'processing') {
            return '';
        }
        return this.activeJob?.ticker ?? '';
    }

    @computed get selectedTickerId() {
        return this.uiDisplayReportId;
    }

    @observable accessor lastReportLoaded: string = '';

    @computed get reportOverride() {
        const ticker = this.selectedTickerId;
        var over_rides = {};
        if (
            this.reportDataOverridesDB &&
            this.reportDataOverridesDB.hasOwnProperty(ticker)
        ) {
            over_rides = this.reportDataOverridesDB[ticker];
        }
        return over_rides;
    }

    @computed get activeReportOriginal() {
        const ticker = this.selectedTickerId;
        if (!this.reportDataDB) {
            return null;
        }

        if (this.reportDataDB.hasOwnProperty(ticker)) {
            if (this.reportDataDB[ticker].status == 'processing') {
                return null;
            }

            if (this.lastReportLoaded != ticker) {
                runInAction(() => {
                    this.lastReportLoaded = ticker;
                    this.pdfImagePage = -1;
                });
            }

            //Override values
            const reportName = this.selectedTickerId;

            //const over_rides = System_Over_Overrides[reportName] || {};

            var over_rides = this.reportOverride;
            return _.cloneDeep(this.reportDataDB[ticker]);
        }

        return null;
    }

    @computed get isLoaded() {
        return this.reportDataDB != null && this.areOveridesLoaded;
    }

    @computed get query(): DataAccessUtils {
        if (!this.activeReportOriginal) {
            return null;
        }

        if (!this.areOveridesLoaded) {
            return null;
        }

        return new DataAccessUtils(
            this.activeReportOriginal,
            this.reportOverride
        );
    }

    @computed
    get company_name() {
        return this.query?.value('company_name');
    }

    @computed
    get ticker() {
        return this.query?.value('ticker');
    }

    @computed get reportCreationDate() {
        if (!this.query?.value('created')) return null;

        return moment.unix(this.query?.value('created')).format('YYYY-MM-D');
    }

    @computed get errorMessageTicker() {
        if (!this.query?.value('error')) return null;
        return this.query?.value('error');
    }
    @computed get reportFiscalYear() {
        if (!this.query?.value('report_fiscal_year')) return null;

        return this.query?.value('report_fiscal_year');
    }

    @computed get companyOverview() {
        return this.query?.value('company_overview');
    }
}

class StoreUI {
    @observable accessor pageTitle = '';
}

const store = new Store();
const storeUI = new StoreUI();

export const useStore = () => store;
export const useStoreUI = () => storeUI;

export const useSetPageTitle = (pageTitle: string) => {
    useEffect(() => {
        storeUI.pageTitle = pageTitle;
    }, [pageTitle]);
};
export const useBackend = () => {
    return new Backend();
};
export default store;
