import {
    getDownloadURL,
    getStorage,
    ref,
    updateMetadata,
    uploadBytesResumable
} from 'firebase/storage';
import { getFirebase } from './FirebaseAdapater';

const STORAGE_PATH = 'gs://cim-project-7b81c.firebasestorage.app/pdfs';
class StorageAdapater {
    async check_if_file_exists(fileName: string) {
        const storage = getStorage();
        const docRef = ref(storage, `${STORAGE_PATH}/${fileName}`);

        try {
            await getDownloadURL(docRef);
            return true;
        } catch (error) {
            return false;
        }
    }

    async uploadPdf(file: File, onProgress?: (progress: number) => void) {
        const fb = getFirebase();
        const userId = fb.userId;
        const userEmail = fb.user.email;

        const storage = getStorage();
        const storageRef = ref(storage, `${STORAGE_PATH}/${file.name}`);
        const upload_task = uploadBytesResumable(storageRef, file);

        upload_task.on(
            'state_changed',
            snapshot => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress =
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                if (onProgress) {
                    onProgress(progress);
                }
            },
            error => {
                return false;
            },
            () => {}
        );

        const metadata = {
            customMetadata: {
                userId,
                userEmail
            }
        };
        var snapshot = await upload_task;
        await updateMetadata(storageRef, metadata);
        return true;
    }
}

const mStorageService = new StorageAdapater();
export function getStorageAdapater() {
    return mStorageService;
}
