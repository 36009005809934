import {
    CSSProperties,
    LegacyRef,
    MutableRefObject,
    useEffect,
    useRef
} from 'react';

type FlexDirection = 'row' | 'column' | 'row-reverse' | 'column-reverse';
type FlexJustifyContent = 'start' | 'end' | 'center' | 'between' | 'around';
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse';
type FlexAlignItems = 'start' | 'end' | 'center' | 'baseline' | 'stretch';

interface IFlexProps {
    children: React.ReactNode;
    direction?: FlexDirection;
    justify?: FlexJustifyContent;
    wrap?: FlexWrap;
    alignItems?: FlexAlignItems;
    className?: string;
    inline?: boolean;
    style?: CSSProperties | undefined;
    fillCell?: boolean;
    id?: string;
    onResize?: (width: number, height: number) => void;
}

export const Flex = (props: IFlexProps) => {
    const ref = useRef();

    const id = props.id || '';
    const fillCell = props.fillCell || false;
    const direction = props.direction || 'row';
    const justify = props.justify || 'start';
    const wrap = props.wrap || 'nowrap';
    const alignItems = props.alignItems || 'stretch';
    const className = props.className || '';
    const inline = props.inline || false;
    const flexType = inline ? 'd-inline-flex' : 'd-flex';
    const classes = `${flexType} flex-${direction} justify-content-${justify} flex-${wrap} align-items-${alignItems} ${className} ${fillCell ? 'h-100 w-100' : ''}`;

    useEffect(() => {
        if (!props.onResize) {
            return;
        }

        const resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                if (entry.contentBoxSize) {
                    const x = entry.contentBoxSize[0].inlineSize;
                    const y = entry.contentBoxSize[0].blockSize;
                    props.onResize(x, y);
                }
            }
        });

        if (ref.current) {
            resizeObserver.observe(ref.current);
        }
        return () => {
            if (ref.current) {
                resizeObserver.unobserve(ref.current);
            }
        };
    }, [ref]);

    return (
        <div id={id} className={classes} style={props.style} ref={ref}>
            {props.children}
        </div>
    );
};
