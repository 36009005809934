@import '../../utils/global.module.less';
.container {
    padding: @side-padding;
    padding-top: 20px;
    padding-bottom: 15px;
    background-color: white;
}

.title {
    padding-top: 10px;
    font-size: 40px;
    font-family: LustTextMedium;
}

.newDealButton {
    width: 120px;
    height: 50px;
}

.plusIcon {
    &::before {
        transform: translate(0, 3px) !important;
        font-size: 20px;
    }
}
