import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Header } from '@assets/component/Header/Header';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useEffect, useRef } from 'react';
import { i18n } from '../../model/Translation';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';

import * as style from './NewDealPage.module.less';
import { getStorageAdapater } from '@assets/firebase/StorageAdapater';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';

const { Dropzone } = require('dropzone');

const { t } = i18n;

const NewDealPage = observer(() => {
    const store = useStore();
    const dropzoneRef = useRef(null);

    useEffect(() => {
        let myDropzone = new Dropzone(dropzoneRef.current, {
            dictDefaultMessage: '',
            maxFilesize: 20, // MB
            url: '/',
            addRemoveLinks: true,
            method: 'put',
            chunking: true,
            forceChunking: true,
            autoQueue: false,
            autoProcessQueue: false,
            disablePreviews: false
        });

        myDropzone.on('addedfile', async function (file) {
            const storage = getStorageAdapater();
            const name = file.name;
            if (!name.endsWith('.pdf')) {
                alert('Please upload a PDF file');
                myDropzone.removeFile(file);
                return;
            }

            var fileExists = await storage.check_if_file_exists(name);
            if (!fileExists) {
                alert('Uploading file');
                const success = await storage.uploadPdf(file, progress => {
                    file.upload.progress = progress;
                    myDropzone.emit('uploadprogress', file, progress);
                });

                if (success) {
                    alert('Upload done!');
                } else {
                    alert('Trouble uploading the file..');
                }
            } else {
                alert('File already exists');
            }

            alert('Finished upload -> Starting job');
            const fb = getFirebase();
            fb.add_job(file.name);
        });

        return () => {
            while (myDropzone.files.length > 0) {
                myDropzone.removeFile(myDropzone.files[0]);
            }

            myDropzone.destroy();
        };
    }, [dropzoneRef]);

    return (
        <>
            <div
                className="d-flex justify-content-center"
                style={{ height: '100%' }}
            >
                <Flex
                    direction="column"
                    className="h-100"
                    style={{ width: '95%' }}
                >
                    <Header disableTicker={true} />

                    <div
                        id="taskPageDropZone"
                        className={style.taskPageDropZone + ' dropzone'}
                        ref={dropzoneRef}
                    >
                        Drop File
                    </div>
                </Flex>
            </div>
        </>
    );
});

export default withRouter(NewDealPage);
