import { observer } from 'mobx-react';
import { ScoreBox } from '../../ScoreBox/ScoreBox';
import { Flex } from '@assets/component/UI/Flex/Flex';
import * as style from './ScoreSummary.module.less';
import { useStore } from '@assets/model/Store';

export const ScoreSummary = observer(() => {
    const store = useStore();
    const rating = [
        'Overall Score',
        'Business Score',
        'Financial Score',
        'Industry Score'
    ].map(i => {
        var s: any = 'N/A';
        if (i == 'Overall Score') {
            s = store.query.score();
        }
        return <ScoreBox key={i} name={i} rating={s} />;
    });

    return <Flex className={style.container}>{rating}</Flex>;
});
