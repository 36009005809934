import { useStoreUI } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import { Button } from 'react-bootstrap';
import { Flex } from '../UI/Flex/Flex';
import * as style from './Header.module.less';
import { Logo } from './Logo';
import { UploadDeal } from '../UploadDeal/UploadDeal';

interface IHeader {
    disableTicker?: boolean;
}

export const Header = observer((p: IHeader) => {
    const store = useStoreUI();
    const navUtils = useNavUtils();
    return (
        <Flex direction="column" className={style.container}>
            <Logo />
            <Flex direction="row">
                <div className={style.title}>{store.pageTitle}</div>
                <div className="flex-grow-1"></div>
            </Flex>
        </Flex>
    );
});
