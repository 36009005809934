import { Flex } from '@assets/component/UI/Flex/Flex';
import { observer } from 'mobx-react';
import * as style from './DealScore.module.less';
import classNames from 'classnames';

interface IDealScoreProps {
    score: number;
    className?: string;
}

export const DealScore = observer((p: IDealScoreProps) => {
    var icon = 'bi bi-check';
    var text = 'Strong';
    var color = '#00A171';
    var score: any = p.score;
    if (p.score > 85) {
        //defaults
    } else if (p.score == -1) {
        icon = 'bi bi-exclamation';
        text = 'Error';
        color = '#3E0000';
        score = 'N/A';
    } else if (p.score > 60) {
        //Medium
        icon = 'bi bi-dash';
        text = 'Med';
        color = '#FF9F00';
    } else {
        icon = 'bi bi-x';
        text = 'Weak';
        color = '#DD2222';
    }

    var iconCls = classNames('bi', icon, style.icon);
    return (
        <Flex
            className={style.scoreBox + ' ' + p.className}
            style={{ backgroundColor: color }}
            justify="between"
        >
            <i className={iconCls}></i>
            <div className={style.text}>{text}</div>
            <div className={style.numberScore}>{score}</div>
        </Flex>
    );

    //<div>{p.score}</div>;
});
