import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { useEffect, useRef } from 'react';
import { toast } from 'react-toastify';

export const ActiveJob = observer(() => {
    const activeJobToaster = useRef(null);
    const store = useStore();
    useEffect(() => {
        if (store.activeJob && store.activeJob.status == 'processing') {
            if (activeJobToaster.current == null) {
                activeJobToaster.current = toast.info(`Analysis \r\n ${store.activeJob.task}` {
                    progress: 0,
					autoClose: false,
					closeButton: false,
                });
			}
			
			const progress = Math.min(store.activeJob.progress || 0, 0.99);

			toast.update(activeJobToaster.current, { render: `Analysis \r\n ${store.activeJob.task}`, progress });
        } else {
            if (activeJobToaster.current) {
                toast.dismiss(activeJobToaster.current);
                activeJobToaster.current = null;
            }
        }
    }, [store.activeJob]);
    return null;
});
