.trashIcon {
    margin-left: 30px;
    transform: translateY(10px);
    &:hover {
        color: darkred;
    }
}

.thumb {
    min-width: 650px;
    max-width: 1500px;
    flex-basis: 1;
    flex-grow: 1;
    height: 400px;
    padding: 30px;
    margin-left: 0;
    margin-right: 10px;
    background-color: white;
    border-radius: 20px;
    margin-bottom: 15px;
    cursor: pointer;
    position: relative;
    &:hover {
        filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.3));
    }
}

.title {
    font-size: 40px;
    font-family: Hanken Grotesk;
    font-weight: 600;
}

.unlockIcon {
    font-size: 20px;
}
.unlock {
    width: 60px;
    font-family: Hanken Grotesk;
    font-weight: 600;
    color: #333333;
    margin-bottom: 20px;
}
.input {
    width: 100%;
    width: 300px;
    height: 50px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 0 15px;
    font-size: 16px;
    color: #333333;
    margin-bottom: 20px;
    &:focus {
        border: 1px solid #333333;
    }
}
