.taskPageDropZone {
    margin: 0 !important;
    padding: 0 !important;
    text-align: center;
    vertical-align: middle;
    height: 500px;
    border-radius: var(--bs-border-radius) !important;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
    &:hover {
        filter: drop-shadow(0 0 1px var(--bs-primary));
    }
}
