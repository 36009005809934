import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import * as style from './DealMissingPassword.module.less';
import { useRef } from 'react';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { Button, Form, InputGroup } from 'react-bootstrap';
interface IDealDealProcessingProps {
    jobId: string;
}
export const DealMissingPassword = observer((p: IDealDealProcessingProps) => {
    const pdfPasswordRef = useRef(null);
    const store = useStore();
    const navUtils = useNavUtils();
    const deal = store.reportDataDB[p.jobId];
    if (!deal) {
        return null;
    }
    return (
        <div className={style.thumb}>
            <Flex
                justify="between"
                alignItems="center"
                direction="column"
                fillCell={true}
                style={{ padding: '10px 0px' }}
            >
                <div className={style.title}>{deal.errorText}</div>
                <div className={style.title} style={{ fontSize: '30px' }}>
                    {deal?.pdf}
                </div>
                <Flex
                    fillCell={true}
                    style={{ maxHeight: '40px', padding: '0px 10px' }}
                >
                    <InputGroup>
                        <Form.Control
                            className={style.input}
                            placeholder="PDF Unlock password?"
                            aria-label="PDF Unlock password?"
                            ref={pdfPasswordRef}
                        />
                        <Button
                            className={style.unlock}
                            onClick={() => {
                                const pass = pdfPasswordRef.current.value;
                                const fb = getFirebase();
                                const pdf = store.reportDataDB[p.jobId]?.pdf;
                                fb.add_job(pdf, pass, p.jobId);
                            }}
                        >
                            <i
                                className={'bi bi-unlock ' + style.unlockIcon}
                            ></i>
                        </Button>
                    </InputGroup>

                    <i
                        className={'bi bi-trash ' + style.trashIcon}
                        onClick={() => {
                            const fb = getFirebase();
                            const yesNo = confirm(
                                'Are you sure you want to delete this report?'
                            );

                            if (yesNo) {
                                fb.deleteReport(p.jobId);
                            }
                        }}
                    ></i>
                </Flex>
            </Flex>
        </div>
    );
});
