.scoreBox {
    background-color: green;
    border-radius: 20px;
    border: 1px solid white;
    height: 42px;
    padding: 5px 0px;
    transform: scale(0.8);
}

.icon {
    font-size: 20px;
    color: white;
    transform: scale(1.5) translateY(-1px);
    margin-left: 10px;
    width: 32px;
}

.text {
    color: white;
    font-size: 20px;
    font-weight: 600;
    align-self: left;
    transform: translateY(-2px);
    margin-right: 6px;
}

.numberScore {
    border: none;
    margin: 6px;
    border-radius: 20px;

    background-color: white;
    width: 40px;
    font-size: 18px;
    color: black;
    font-weight: 600;
    justify-content: right;
    align-self: center;
    text-align: center;
    transform: translateY(-1px);
}
