import {
    PanelColumn,
    PanelRow,
    PanelTable
} from '@assets/component/Panel/PanelTable/PanelTable';
import { PanelTitle } from '@assets/component/Panel/PanelTitle/PanelTitle';
import { useStore } from '@assets/model/Store';
import { formatPercentStr } from '@assets/utils/Formatting';
import { RowLabel } from '../../PanelElements/RowLable/RowLable';
import { RowValue } from '../../PanelElements/RowValue/RowValue';

export const PanelCAGR = () => {
    const data = {};
    const query = useStore().query;
    const cols: PanelColumn[] = [
        { column: { source: '2', display: '2 Years' } },
        { column: { source: '3', display: '3 Years' } },
        { column: { source: '4', display: '4 Years' } },
        { column: { source: '5', display: '5 Years' } },
        {
            column: { source: null, display: 'Source' },
            element: RowValue.sources,
            dontUseRowFormatting: true,
            overrideValue: query.sources.bind(query)
        }
    ];

    const rowDefaults = {
        element: RowValue.percent,
        label: RowLabel.percent_label,
        formatter: data => formatPercentStr(data.toString())
    };

    const rows: PanelRow[] = [
        {
            ...rowDefaults,
            field: {
                source: 'net_revenue_per_year',
                display: 'Historical Organic CAGR'
            },
            value: (field, column) => {
                const yearCalc = parseInt(column.source);
                return query.organicCagr(yearCalc);
            }
        },
        {
            ...rowDefaults,
            field: {
                source: 'net_revenue_per_year',
                display: 'Projected Organic CAGR'
            },
            value: (field, column) => {
                const yearCalc = parseInt(column.source);
                return query.projectedCagr(yearCalc);
            }
        }
    ];

    return (
        <>
            <PanelTitle title="Revenue CAGRs" />
            <PanelTable columns={cols} rows={rows} disable_round_edges={true} />
        </>
    );
};
