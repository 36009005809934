import { observer } from 'mobx-react';

import { useStore } from '@assets/model/Store';
import 'dropzone/dist/basic.css';
import 'dropzone/dist/dropzone.css';
import { useEffect, useRef } from 'react';
import { i18n } from '../../model/Translation';

import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { getStorageAdapater } from '@assets/firebase/StorageAdapater';
import * as style from './UploadDeal.module.less';
import { toast } from 'react-toastify';
const { Dropzone } = require('dropzone');

const { t } = i18n;

export const UploadDeal = observer(() => {
    const store = useStore();
    const dropzoneRef = useRef(null);

    useEffect(() => {
        let myDropzone = new Dropzone(dropzoneRef.current, {
            dictDefaultMessage: '',
            maxFilesize: 20, // MB
            url: '/',
            addRemoveLinks: false,
            method: 'put',
            chunking: true,
            forceChunking: true,
            autoQueue: false,
            autoProcessQueue: false,
            disablePreviews: true
        });

        myDropzone.on('addedfile', async function (file) {
            const storage = getStorageAdapater();
            const name = file.name;
            if (!name.toLowerCase().endsWith('.pdf')) {
                toast.error('Please upload a PDF file');
                myDropzone.removeFile(file);
                return;
            }
            const fb = getFirebase();
            fb.store_audit_entry('UPLOAD_CIM', { name });

            const target_filename = name;
            var fileExists = await storage.check_if_file_exists(name);
            if (!fileExists) {
                var toasterId = toast.info('Uploading', {
                    progress: 0,
                    autoClose: false
                });

                //alert('Uploading file');
                const success = await storage.uploadPdf(file, progress => {
                    //file.upload.progress = progress;
                    toast.update(toasterId, {
                        progress: progress / 100
                    });
                });

                if (success) {
                    //alert('Upload done!');
                    toast.done(toasterId);
                } else {
                    toast.info('Trouble uploading the file..');
                }
            } else {
                //alert('File already exists');
            }

            fb.add_job(file.name);
        });

        return () => {
            while (myDropzone.files.length > 0) {
                myDropzone.removeFile(myDropzone.files[0]);
            }

            myDropzone.destroy();
        };
    }, [dropzoneRef]);

    return (
        <>
            <div
                ref={dropzoneRef}
                id="taskPageDropZone"
                className={style.taskPageDropZone}
            >
                <i
                    className={'bi bi-file-earmark-plus ' + style.uploadIcon}
                ></i>
            </div>
        </>
    );
});
