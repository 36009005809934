import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import { observer } from 'mobx-react';
import * as style from './DealProcessing.module.less';
import spinner from './tube-spinner.svg';
import { unknown } from 'zod';
interface IDealDealProcessingProps {
    jobId: string;
}
export const DealProcessing = observer((p: IDealDealProcessingProps) => {
    const store = useStore();
    const navUtils = useNavUtils();

    const jobs = store.jobs;
    if (store.activeJob?.jobId == p.jobId) {
        return (
            <div className={style.thumb}>
                <Flex
                    justify="between"
                    alignItems="center"
                    direction="column"
                    fillCell={true}
                    style={{ padding: '10px 0px' }}
                >
                    <img
                        src={spinner}
                        style={{ width: '200px', height: '200px' }}
                    />

                    <div className={style.filenameText}>
                        {store.activeJob?.fileName}
                    </div>
                </Flex>
            </div>
        );
    } else {
        const queueString = 'In Queue';

        return (
            <div className={style.thumb}>
                <Flex
                    justify="between"
                    alignItems="center"
                    direction="column"
                    fillCell={true}
                    style={{ padding: '10px 0px' }}
                >
                    <div className={style.title}>
                        {queueString}{' '}
                        {store.jobs[p.jobId]?.pdfPassword !== undefined && (
                            <i
                                className="bi bi-asterisk"
                                style={{ fontSize: 25 }}
                            ></i>
                        )}
                    </div>
                    <div className={style.filenameText}>
                        {store.jobs[p.jobId]?.fileName}
                    </div>
                </Flex>
            </div>
        );
    }
});
