import * as gstyle from '../../utils/global.module.less';
import { Panel } from '../Panel/Panel';
import { Split } from '../Split/Split';
import { Flex } from '../UI/Flex/Flex';
import { ContentHeader } from './ContentHeader/ContentHeader';

import store, {
    useSetPageTitle,
    useStore,
    useStoreUI
} from '@assets/model/Store';
import { observer } from 'mobx-react';
import { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { PanelCompanyFinancials } from '../Financial/PanelCompanyYearly/PanelCompanyFinancials';
import { PanelIndustry } from '../Financial/PanelIndustry/PanelIndustry';
import { PanelCompanyOverview } from '../Panel/PanelCompanyOverview/PanelCompanyOverview';
import { PanelImage } from '../Panel/PanelImage/PanelImage';
import { PanelReportNavigation } from '../Panel/PanelReportNavigation/PanelReportNavigation';
import { ScrollPanel } from '../Panel/ScrollPanel/ScrollPanel';
import dragIcon from './dragIcon.svg';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { toJS } from 'mobx';
import { useNavUtils } from '@assets/utils/NavUtils';

export const Content = observer(() => {
    const pdfRef = useRef(null);
    const store = useStore();
    const nav = useNavUtils();

    const [contentSize, setContentSize] = useState(store.defaultContentSize);
    const [dragPos, setDragPos] = useState(-100);
    const [winWidth, setWindowWidth] = useState(0);
    const [pdfViewer, setPdfViewer] = useState(null);

    const repositionDragable = () => {
        setDragPos(winWidth * (contentSize / 100));
    };

    useEffect(
        function auditReportView() {
            getFirebase().store_audit_entry('VIEW_PAGE_DEAL', {
                page: 'DealView',
                deal_id: store.uiDisplayReportId,
                url: window.location.href
            });
        },
        [store.uiDisplayReportId]
    );

    useEffect(function handleResize() {
        const onResize = () => {
            const contWidth = document
                .getElementById('content_container')
                .getBoundingClientRect().width;

            setWindowWidth(contWidth);
        };

        window.addEventListener('resize', onResize);

        const initTimeout = () => {
            setTimeout(() => {
                if (document.getElementById('content_container')) {
                    onResize();
                } else {
                    initTimeout();
                }
            }, 200);
        };

        initTimeout();
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        repositionDragable();
    }, [winWidth, contentSize]);

    useEffect(
        function setupAdobeViewer() {
            // @ts-ignore
            if (!window.AdobeDC) {
                return;
            }

            if (pdfRef.current == null) {
                return;
            }

            const pdfFile: string = toJS(store.pdfFileUrl);
            const companyName: string = toJS(store.company_name);

            if (!pdfFile || pdfFile == '') {
                return;
            }

            //LOCALHOST KEY 95c70d4882b846f7899dbcd35e0ce896
            const isLocalhost = window.location.href.indexOf('localhost') > -1;
            var prod_key = '95c70d4882b846f7899dbcd35e0ce896';
            var localhost_key = '2042181d883b4bddaabbc5930d48b302';
            const pdf_key = isLocalhost ? localhost_key : prod_key;
            // @ts-ignore
            var adobeDCView = new AdobeDC.View({
                clientId: pdf_key,
                divId: 'adobe-dc-view'
            });

            var previewFilePromise = adobeDCView.previewFile(
                {
                    content: {
                        location: {
                            url: pdfFile
                        }
                    },
                    metaData: { fileName: companyName }
                },
                {
                    embedMode: 'SIZED_CONTAINER',
                    enableFormFilling: false
                }
            );

            previewFilePromise.then(adobeViewer => {
                setPdfViewer(adobeViewer);
            });
        },
        [pdfRef, store.pdfFileUrl]
    );

    useEffect(
        function updatePdfViewerPage() {
            if (store.pdfImagePage != -1 && pdfViewer != null) {
                pdfViewer.getAPIs().then(apis => {
                    apis.gotoLocation(store.pdfImagePage, 0, 0)
                        .then(result => console.log(result))
                        .catch(error => console.log(error));
                });
            }
        },
        [store.pdfImagePage]
    );

    useSetPageTitle(store.query?.value('company_name') ?? '');

    if (!store.query) {
        return null;
    }

    const size = [contentSize, 100 - contentSize];

    return (
        <>
            <Draggable
                axis="x"
                handle=".handle"
                bounds={{ left: 200, right: winWidth - 200 }}
                position={{
                    x: dragPos,
                    y: 0
                }}
                positionOffset={{ x: -5, y: 320 }}
                scale={1}
                onDrag={(e, data) => {
                    document.getElementById(
                        'adobe-dc-view'
                    ).style.pointerEvents = 'none';
                    var x = data.x;
                    var normalized = x / winWidth;

                    setDragPos(x);
                    setContentSize(normalized * 100);
                    store.defaultContentSize = normalized * 100;
                }}
                onStop={(e, data) => {
                    document.getElementById(
                        'adobe-dc-view'
                    ).style.pointerEvents = 'auto';
                }}
            >
                <div
                    className="handle"
                    style={{
                        zIndex: 60000,
                        cursor: 'pointer',
                        position: 'absolute'
                    }}
                >
                    <img src={dragIcon} style={{ pointerEvents: 'none' }}></img>
                </div>
            </Draggable>
            <Flex direction="column" id="content_container">
                <ContentHeader />
                <Split
                    layoutPercent={size}
                    style={{ backgroundColor: 'white' }}
                >
                    <Flex
                        direction="column"
                        className={gstyle.defaultPadding}
                        style={{ paddingRight: '10px' }}
                    >
                        <ScrollPanel>
                            <PanelCompanyOverview />
                            <PanelCompanyFinancials />
                            <PanelIndustry />
                        </ScrollPanel>
                    </Flex>

                    <Panel className={gstyle.panelImage} shadow={true}>
                        {/* <PanelHeader title="PDF" /> */}
                        {/* <PanelImage src={store.pdfImageUrl} /> */}
                        <div
                            ref={pdfRef}
                            id="adobe-dc-view"
                            style={{ zIndex: 6000, borderRadius: '20px' }}
                        ></div>
                    </Panel>
                </Split>
            </Flex>
            {store.showDeleteButton && (
                <input
                    type="button"
                    value="Delete"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px'
                    }}
                    onClick={() => {
                        alert('This action will delete this report!');
                        var areYouSure = window.confirm(
                            'Are you sure you want to delete this report?' +
                                '\r\nReport Name:' +
                                store.uiDisplayReportId +
                                '\r\nCompany: ' +
                                store.query.value('company_name')
                        );
                        if (areYouSure) {
                            const fb = getFirebase();
                            fb.deleteReport(store.uiDisplayReportId);
                            nav.home();
                        }
                    }}
                />
            )}

            {store.showExportButton && (
                <input
                    type="button"
                    value="Export"
                    style={{
                        position: 'absolute',
                        right: '10px',
                        bottom: '10px'
                    }}
                    onClick={() => {
                        //Fields -
                        /*
						3-Year Historical Company Organic Revenue Growth
						3-Year Projected Company Organic Revenue Growth
						EBITDA (most recent actual)
						EBITDA Margins (most recent actual)
						EBITDA Margins (two years ago, so I can calculate the change)
						Adjusted EBITDA (most recent actual)
						Total Capex % of Revenue (most recent actual)
						Gross Retention (Lost Only) (most recent actual)
						Net Retention (most recent actual)
						NWC as % of Revenue (most recent actual)
						FCF Conversion (most recent actual)
						TAM ($B)
						Historical Market Growth
						Projected Market Growth
						Contractual Revenue (Y/N)
					*/
                        const q = store.query;
                        const year = q.actualYear();

                        const organicCagr = q.organicCagr(3);
                        const projectedOrganicCagr = q.projectedCagr(3);
                        const ebitda = q.valueByYear('ebitda_per_year', year);
                        const ebitdaMargin = q.valueByYearDivideByRevenue(
                            'ebitda_per_year',
                            year
                        );

                        const netRevenue = q.valueByYear(
                            'net_revenue_per_year',
                            year
                        );
                        const ebitdaMargin2YearsAgo =
                            q.valueByYearDivideByRevenue(
                                'ebitda_per_year',
                                year,
                                -2
                            );

                        const adjustedEbidta = q.valueByYear(
                            'adjusted_ebitda_per_year',
                            year
                        );

                        const totalCapexByRev = q.valueByYearDivideByRevenue(
                            'capex_per_year',
                            year
                        );

                        const gross_retention = q.valueByYearDivideByRevenue(
                            'gross_retention_lost_only_per_year',
                            year
                        );

                        const netRet = q.valueByYear(
                            'net_retention_yearly',
                            year
                        );

                        const capexPerYear = q.valueByYear(
                            'capex_per_year',
                            year
                        );
                        const maintenanceCapexPerYear = q.valueByYear(
                            'maintenance_capex_per_year',
                            year
                        );

                        const ebitdaCashflow = q.valueByYear(
                            'ebitda_cashflow_yearly',
                            year
                        );

                        const totalAssets = q.valueByYear(
                            'total_current_assets_yearly',
                            year
                        );
                        const totalLiabilities = q.valueByYear(
                            'total_current_liabilities_yearly',
                            year
                        );

                        const tam = q.value('total_addressable_market');
                        const historicalMarketGrowth = q.value(
                            'historical_market_growth'
                        );
                        const projectedMarketGrowth = q.value(
                            'projected_market_growth'
                        );
                        const contractualRevenue = q.value(
                            'contractual_revenue'
                        );

                        const rows = [];
                        const addValue = (name, value) => {
                            rows.push(`${name},${value}`);
                        };

                        addValue(
                            'company_name',
                            store.query.activeReport.company_name
                        );
                        addValue('net_revenue', netRevenue);
                        addValue('organic_cagr_3_years', organicCagr);
                        addValue(
                            'projected_organic_cagr_3_years',
                            projectedOrganicCagr
                        );
                        addValue('ebitda', ebitda);
                        addValue('ebitda_margin', ebitdaMargin);
                        addValue(
                            'ebitda_margin_2_years_ago',
                            ebitdaMargin2YearsAgo
                        );
                        addValue('adjusted_ebitda', adjustedEbidta);
                        addValue('total_capex_by_rev', totalCapexByRev);
                        addValue('gross_retention', gross_retention);
                        addValue('net_retention', netRet);
                        addValue('capex_per_year', capexPerYear);
                        addValue(
                            'maintenance_capex_per_year',
                            maintenanceCapexPerYear
                        );
                        addValue('free_cash_flow', ebitdaCashflow);
                        addValue('total_assets', totalAssets);
                        addValue('total_liabilities', totalLiabilities);
                        addValue('tam', tam);
                        addValue(
                            'historical_market_growth',
                            historicalMarketGrowth
                        );
                        addValue(
                            'projected_market_growth',
                            projectedMarketGrowth
                        );
                        addValue('contractual_revenue', contractualRevenue);

                        alert('Copied to clipboard');

                        navigator.clipboard.writeText(rows.join('\n'));
                    }}
                />
            )}
        </>
    );
});
