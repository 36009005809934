import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './Home';
import LoginPage from './Login';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import ChatUI from './ChatUI';
import DealDBPage from './DealDBPage';
import NewDealPage from './NewDeal/NewDealPage';
import { ToastContainer } from 'react-toastify';
import { ActiveJob } from '@assets/component/ActiveJob/ActiveJob';
export const PageFrame = observer(() => {
    const store = useStore();
    return (
        <>
            <ToastContainer position="bottom-right" />
            <ActiveJob />
            <Router>
                <Routes>
                    <Route path="/" element={<DealDBPage />} />
                    <Route path="/db" element={<DealDBPage />} />
                    {/* <Route path="/chat" element={<ChatUI />} /> */}
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/:id" element={<HomePage />} />
                    <Route path="/add-new-deal" element={<NewDealPage />} />
                </Routes>
            </Router>
        </>
    );
});
