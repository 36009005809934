import { Backend } from '@assets/backend/backend';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, User } from 'firebase/auth';
import {
    Database,
    getDatabase,
    onValue,
    push,
    ref,
    serverTimestamp,
    set
} from 'firebase/database';
import { getStorage } from 'firebase/storage';
import { useStore } from 'src/model/Store';
import { setInterval } from 'timers/promises';

interface FileUpload {
    url: string;
    name: string;
    thumbnail: string;
}

type AuditEntryType =
    | 'LOGIN'
    | 'APP_LOAD'
    | 'VIEW_PAGE_DEAL'
    | 'VIEW_PAGE_DB'
    | 'EDIT_FIELD'
    | 'ADD_NEW_DEAL'
    | 'DOWNLOAD_CIM'
    | 'UPLOAD_CIM'
    | 'DELETE_DEAL';

class FirebaseAdapater {
    app: FirebaseApp;
    db: Database;
    userId: string;
    user: User = null;

    init() {
        const firebaseConfigHarvest = {
            apiKey: 'AIzaSyAvepjWXQW2-CcZO8rlT_5KizVrtcoxdLk',
            authDomain: 'cim-project-7b81c.firebaseapp.com',
            databaseURL:
                'https://cim-project-7b81c-default-rtdb.firebaseio.com',
            projectId: 'cim-project-7b81c',
            storageBucket: 'cim-project-7b81c.appspot.com',
            messagingSenderId: '289364347508',
            appId: '1:289364347508:web:e9c4d31793e4659e0a9f21'
        };

        // const firebaseConfigLilJohn = {
        //     apiKey: 'AIzaSyAe4sep50Un5IldWeD16Gbu3g_eJ_epFiY',
        //     authDomain: 'littlejohn-12e76.firebaseapp.com',
        //     projectId: 'littlejohn-12e76',
        //     storageBucket: 'littlejohn-12e76.firebasestorage.app',
        //     messagingSenderId: '591787070198',
        //     appId: '1:591787070198:web:873795f88251eae061d2d7'
        // };
        const firebaseConfig = firebaseConfigHarvest;
        // Initialize Firebase
        console.log('Initializing Firebase');
        this.app = initializeApp(firebaseConfig);
        this.db = getDatabase(this.app);
        var auth = getAuth(this.app);

        onAuthStateChanged(auth, user => {
            if (user) {
                console.log('Logged in!');
                this.userId = user.uid;
                this.user = user;

                this.store_audit_entry('APP_LOAD');

                const backend = new Backend();
                var r = ref(this.db, '/cim_results');
                onValue(r, data => {
                    const val = data.val();
                    //console.log(JSON.stringify(val));
                    const store = useStore();
                    store.reportDataDB = val;

                    const waitingFor = store.waitingForTickerToFinish;
                    if (waitingFor != '') {
                        if (val.hasOwnProperty(waitingFor)) {
                            location.href = '/' + waitingFor;
                            return;
                        }
                    }

                    if (store.uiDisplayReportId == '') {
                        const keys = Object.keys(val);
                        if (keys.length > 0) {
                            store.uiDisplayReportId = Object.keys(val)[0];
                        }
                    }
                });

                var r_overrides = ref(this.db, '/cim_results_overrides');
                onValue(r_overrides, data => {
                    const val = data.val();
                    //console.log(JSON.stringify(val));
                    const store = useStore();
                    store.reportDataOverridesDB = val || {};
                    store.areOveridesLoaded = true;
                });

                var r_settings = ref(this.db, '/settings');
                onValue(r_settings, data => {
                    const val = data.val();
                    //console.log(JSON.stringify(val));
                    const store = useStore();
                    store.settings = val || {};
                });

                var r = ref(this.db, '/jobs');
                onValue(r, data => {
                    const store = useStore();
                    store.jobs = data.val();
                });

                var activeJobs = ref(this.db, '/activeJob');
                onValue(activeJobs, data => {
                    const store = useStore();
                    store.activeJob = data.val();
                });

                // var onlineStatus = ref(this.db, '/system_status');
                // onValue(onlineStatus, data => {
                //     store.systemStatus = data.val();
                // });
            } else {
                if (location.href.indexOf('login') < 0) {
                    location.href = '/login';
                }
            }
        });
    }

    deleteReport(reportId: string) {
        this.store_audit_entry('DELETE_DEAL', {
            reportId
        });

        var r = ref(this.db, `/cim_results/${reportId}`);
        set(r, null);
    }

    store_override(reportId: string, overidePath: string, value: any) {
        const path = `cim_results_overrides/${reportId}/${overidePath}`;
        const r = ref(this.db, path);
        set(r, value);

        this.store_audit_entry('EDIT_FIELD', {
            reportId: reportId,
            field: overidePath,
            value: value
        });
    }

    remove_override(reportId: string, overidePath: string) {
        const path = `cim_results_overrides/${reportId}/${overidePath}`;
        const r = ref(this.db, path);
        set(r, null);

        this.store_audit_entry('EDIT_FIELD', {
            reportId: reportId,
            field: overidePath,
            value: 'DELETED'
        });
    }

    store_audit_entry(type: AuditEntryType, extraData: any = {}) {
        if (!this.userId) {
            setTimeout(() => {
                this.store_audit_entry(type, extraData);
            }, 1000);
            return;
        }
        const userId = this.userId;
        const userEmail = this.user.email;

        const path = `audit_log`;
        const r = ref(this.db, path);

        push(r, {
            type: type,
            userId: userId,
            userEmail: userEmail,
            extraData: extraData,
            date: serverTimestamp()
        });
    }

    add_job(fileName: string, password?: string, originalJobId?: string) {
        const r = ref(this.db, `/jobs`);
        const data = {
            fileName: fileName,
            user: getAuth().currentUser.email,
            userId: getAuth().currentUser.uid,
            status: 'pending',
            createdAt: serverTimestamp()
        };

        if (password) {
            data['pdfPassword'] = password;
        }

        if (originalJobId) {
            data['originalJobId'] = originalJobId;
        }

        push(r, data);
    }
}

const mFirebaseService = new FirebaseAdapater();
export function getFirebase() {
    return mFirebaseService;
}
