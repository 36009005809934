import { Panel } from '@assets/component/Panel/Panel';
import { PanelDivider } from '@assets/component/Panel/PanelDivider/PanelDivider';
import { PanelHeader } from '@assets/component/Panel/PanelHeader/PanelHeader';
import { useStore } from '@assets/model/Store';
import { observer } from 'mobx-react';
import { PanelFinanceYearly } from './SubPanels/PanelFinanceYearly';
import { PanelKeyMetrics } from './SubPanels/PanelKeyMetrics';
import { PanelCAGR } from './SubPanels/CAGR/PanelCAGR';
import { PanelIndustry } from '../PanelIndustry/PanelIndustry';
import { PanelEnd } from '@assets/component/Panel/PanelEnd/PanelEnd';

export const PanelCompanyFinancials = observer(() => {
    const store = useStore();
    const data = store.query.activeReport;

    if (!data) {
        return null;
    }

    return (
        <Panel shadow={true}>
            <PanelHeader title="Financials" />
            <PanelFinanceYearly />
            <PanelDivider />
            <PanelCAGR />
            <PanelDivider />
            <PanelKeyMetrics />
        </Panel>
    );
});
