.title {
    font-family: Hanken Grotesk;
    font-size: 15px;
    font-weight: 600;
    color: #667085;
}

.value {
    font-family: Hanken Grotesk;
    font-size: 15px;
    color: #667085;
}
