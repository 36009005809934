import {
    EditTableStore,
    PanelColumn,
    PanelRow
} from '@assets/component/Panel/PanelTable/PanelTable';
import { Typeahead } from 'react-bootstrap-typeahead';
import * as style from './RowEditor.module.less';
import industry from '@assets/model/industry_data.json';
import { observer } from 'mobx-react';

export interface RowEditorProps {
    row: PanelRow;
    col: PanelColumn;
    val: EditTableStore;
    onEdit: (v: string, save: boolean) => void;
    requestUpdateField: (field: string, value: boolean) => void;
}

const TextBoxEditor = observer((p: RowEditorProps) => {
    return (
        <input
            autoFocus={true}
            type="text"
            value={p.val.editCellValue}
            style={{ width: '100%' }}
            onKeyDown={e => {
                if (e.key === 'Enter') {
                    const newValue = (e.target as HTMLInputElement).value;
                    p.val.editCellValue = newValue;
                    p.onEdit(newValue, true);
                }
            }}
            onInput={e => {
                const newValue = (e.target as HTMLInputElement).value;
                p.val.editCellValue = newValue;
            }}
        />
    );
});

const IndustryEditor = observer((p: RowEditorProps) => {
    const options = industry.map(industry => {
        var name = industry.name;
        return { label: name, value: name, industry: name };
    });

    return (
        <Typeahead
            className={style.editIndustry}
            dropup={true}
            id="typeahead-industry-edit"
            labelKey="label"
            positionFixed={true}
            onChange={selected => {
                if (selected.length > 0) {
                    p.onEdit(selected[0]['value'], true);
                }
            }}
            options={options}
            placeholder={p.val.editCellValue}
            open={true}
        />
    );
});

const SubIndustryEditor = observer((p: RowEditorProps) => {
    const industryValue = p.val.dealData['industry'];
    const subIndustry = industry.find(
        industry => industry.name == industryValue
    ).subtypes;

    const options = subIndustry.map(industry => {
        var name = industry.name;
        return { label: name, value: name, industry: name };
    });

    return (
        <Typeahead
            className={style.editIndustry}
            dropup={true}
            id="typeahead-industry-edit"
            labelKey="label"
            positionFixed={true}
            onChange={selected => {
                if (selected.length > 0) {
                    p.onEdit(selected[0]['value'], true);
                }
            }}
            options={options}
            placeholder={p.val.editCellValue}
            open={true}
        />
    );
});
export class RowEditor {
    static textbox = (api: RowEditorProps) => {
        return <TextBoxEditor {...api} />;
    };

    static industry = (api: RowEditorProps) => {
        return <IndustryEditor {...api} />;
    };

    static subIndustry = (api: RowEditorProps) => {
        return <SubIndustryEditor {...api} />;
    };
}
