import {
    Field,
    PanelColumn,
    PanelRow
} from '@assets/component/Panel/PanelTable/PanelTable';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import * as style from '../PanelElements.module.less';

import classNames from 'classnames';
import hand_edit_icon from './hand-edit-icon.svg';

export class RowValue {
    static label: (
        field: Field
    ) => ReactElement<any, string | JSXElementConstructor<any>>;
    static sources(row: PanelRow, col: PanelColumn, data: any) {
        //Pencil edit icon
        const pencilEditIcon = (
            <img
                key="hand_icon"
                src={hand_edit_icon}
                alt="edit"
                className={style.source_page_entry + ' ' + style.editHandIcon}
                onClick={() => {
                    var el = document.getElementById('rootContainer');
                    el.style.width = '2px';
                    window.setTimeout(() => {
                        el.style.width = '1px';
                    }, 10);
                }}
            />
        );

        if (row.dontShowSources) {
            data = [];
        }

        const items = [];
        if (!Array.isArray(data)) {
            data = [];
        }

        if (row.editable) {
            var q = useStore().query;

            if (
                q.isValueEditedAtAll(row.field.source) ||
                q.isValueEditedAtAll(row.field.source + '_page_source')
            ) {
                items.push(pencilEditIcon);
            }
        }

        data.map((page: number, index: number) => {
            items.push(
                <div
                    key={page}
                    className={style.source_page_entry}
                    onClick={() => {
                        const store = useStore();
                        store.pdfImagePage = -1;
                        setTimeout(() => {
                            store.pdfImagePage = page;
                        }, 10);
                    }}
                >
                    {page}
                </div>
            );
        });

        var isModified = false;
        if (row.editable) {
            var q = useStore().query;
            if (q.isValueEditedAtAll(row.field.source + '_page_source')) {
                isModified = true;
            }
        }
        const containerClasses = classNames({
            [style.editable]: row.editable,
            [style.modified]: isModified
        });

        return (
            <Flex
                className={containerClasses}
                alignItems="center"
                style={{ minWidth: 100 }}
                wrap="wrap"
            >
                {items}
            </Flex>
        );
    }

    static percent(row: PanelRow, col: PanelColumn, data: any) {
        var isModified = false;
        if (row.editable) {
            var q = useStore().query;
            if (q.isValueEditedByRowCol(row, col)) {
                isModified = true;
            }
        }

        const containerClasses = classNames({
            [style.editable]: row.editable,
            [style.modified]: isModified
        });

        const valueClasses = classNames(style.rowValue, {
            [style.editable]: row.editable,
            [style.modified]: isModified
        });
        return (
            <Flex
                fillCell={true}
                alignItems="center"
                className={containerClasses}
            >
                <div className={valueClasses}>
                    <i>{data.toString()}</i>
                </div>
            </Flex>
        );
    }

    static value(row: PanelRow, col: PanelColumn, data: any) {
        var isModified = false;
        if (row.editable) {
            var q = useStore().query;
            if (q.isValueEditedByRowCol(row, col)) {
                isModified = true;
            }
        }

        const containerClasses = classNames({
            [style.editable]: row.editable,
            [style.modified]: isModified
        });
        const valueClasses = classNames(style.rowValue, {
            [style.editable]: row.editable
        });
        return (
            <Flex
                fillCell={true}
                alignItems="center"
                className={containerClasses}
            >
                <div className={valueClasses}>{data.toString()}</div>
            </Flex>
        );
    }

    static boolean(row: PanelRow, col: PanelColumn, data: any) {
        const yes = <i className="bi bi-check"></i>;
        const no = <i className="bi bi-x"></i>;
        const unsure = <i className="bi bi-question"></i>;
        const icon =
            data === true || data === 1 ? yes : data === false ? no : unsure;
        return (
            <Flex fillCell={true} alignItems="center">
                <div className={style.rowValueBoolean}>{icon}</div>
            </Flex>
        );
    }
}
