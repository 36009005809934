[
    {
        "value": "organic_cagr_3_years",
        "points": 3.6,
        "check": "range",
        "range": [-0.01, 0.1]
    },
    {
        "value": "projected_organic_cagr_3_years",
        "points": 3.6,
        "check": "range",
        "range": [-0.1, 0.1]
    },
    {
        "value": "ebitda",
        "points": 2.9,
        "check": "greater_than",
        "threshold": 30
    },
    {
        "value": "ebitda_margin",
        "points": 2.9,
        "check": "range",
        "range": [0, 0.25]
    },
    {
        "value": "gross_retention",
        "points": 3.6,
        "check": "range",
        "range": [0, 0.98]
    },
    {
        "value": "net_retention",
        "points": 3.6,
        "check": "range",
        "range": [0, 1.2]
    },
    {
        "value": "ebitda_margin_2_year_change",
        "points": 3.5,
        "check": "greater_than",
        "threshold": 0
    },
    {
        "value": "ebitda_adjustment_magnitude",
        "points": 2.9,
        "check": "range",
        "inverse_score": true,
        "range": [0.05, 0.5]
    },
	{
		"value": "fcf_conversion_ebitda",
		"points": 4.0,
		"check": "range",
		"range": [0.25, 0.95]
	}
]
