import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Header } from '@assets/component/Header/Header';
import { Flex } from '@assets/component/UI/Flex/Flex';
import { useStore } from '@assets/model/Store';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { i18n } from '../model/Translation';
import { DealSearchContent } from '@assets/component/DealSearch/DealSearchContent';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';

const { t } = i18n;

const DealDBPage = observer(() => {
    const { id } = useParams();
    const store = useStore();
    useEffect(() => {
        if (id) {
            store.uiDisplayReportId = id;
        }
    }, [id]);

    useEffect(() => {
        getFirebase().store_audit_entry('VIEW_PAGE_DB', { page: 'DealDBPage' });
    }, []);
    return (
        <>
            <div
                className="d-flex justify-content-center"
                style={{ height: '100%' }}
            >
                <Flex
                    direction="column"
                    className="h-100"
                    style={{ width: '95%' }}
                >
                    <Header disableTicker={true} />
                    <DealSearchContent />
                </Flex>
            </div>
        </>
    );
});

export default withRouter(DealDBPage);
