import { useSetPageTitle, useStore, useStoreUI } from '@assets/model/Store';
import { useNavUtils } from '@assets/utils/NavUtils';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { Fragment, useEffect, useState } from 'react';
import {
    Highlighter,
    Menu,
    MenuItem,
    Typeahead
} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import industry from '../../../model/industry_data.json';
import { Flex } from '../../UI/Flex/Flex';
import * as style from './DealSearchHeader.module.less';
import { UploadDeal } from '@assets/component/UploadDeal/UploadDeal';

export const DealSearchHeader = observer(() => {
    const store = useStore();
    const ui = useStoreUI();
    useSetPageTitle('Deals Database');

    const navUtils = useNavUtils();
    const [multiSelectionsIndustry, setMultiSelectionsIndustry] = useState([]);
    const [scoreSelection, setScoreSelection] = useState([]);
    const [textSearch, setTextSearch] = useState('');

    const updateScoreSeletion = (selected: []) => {
        setScoreSelection(selected);
    };
    const updateSelectionIndustry = (selected: []) => {
        var updated = [];

        selected.forEach((selection: any) => {
            if (!updated.find((item: any) => item.value == selection.value)) {
                updated.push({
                    label: selection.value,
                    value: selection.value
                });
            }
        });
        setMultiSelectionsIndustry(updated);
    };

    useEffect(() => {
        store.dealPageIndustrySearch = multiSelectionsIndustry.map(
            selection => selection.value
        );

        store.dealPageScoreSearch = scoreSelection.map(
            selection => selection.value
        );
    }, [multiSelectionsIndustry, scoreSelection]);

    if (!store.isLoaded) {
        return null;
    }

    function filterBy(option, ticker) {
        return (
            option.label.toLowerCase().indexOf(ticker.text.toLowerCase()) > -1
        );
    }

    function filterByReturnAll(option, ticker) {
        return true;
    }

    const braileSpacing = '⠀  ';

    var options_score = [
        { label: 'All', value: 'All' },
        { label: 'Strong', value: 'Strong' },
        { label: 'Medium', value: 'Medium' },
        { label: 'Weak', value: 'Weak' }
    ];

    var options = [];
    industry.forEach(industry => {
        var name = industry.name;
        options.push({ label: name, value: name, industry: name });
        industry.subtypes.forEach(sub_industry => {
            options.push({
                label: braileSpacing + sub_industry.name,
                value: sub_industry.name,
                industry: name
            });
        });
    });

    function groupBy(list, keyGetter) {
        const map = {};
        list.forEach(item => {
            const key = keyGetter(item);
            if (!map.hasOwnProperty(key)) {
                map[key] = [];
            }
            map[key].push(item);
        });

        return map;
    }

    var typeahead_props = {};
    typeahead_props['renderMenu'] = (
        results,
        {
            newSelectionPrefix,
            paginationText,
            renderMenuItemChildren,
            ...menuProps
        },
        state
    ) => {
        let index = 0;
        const list = groupBy(results, a => a.industry);
        const items = Object.keys(list)
            .sort()
            .map(region => {
                var subitems = list[region];
                var header = <Menu.Header>{region}</Menu.Header>;
                if (subitems[0].label == region) {
                    //kind of hackish
                    header = null;
                }

                return (
                    <Fragment key={region}>
                        {index !== 0 && <Menu.Divider />}
                        {header}
                        {subitems.map(i => {
                            var isSelected = multiSelectionsIndustry.find(
                                a => a.value == i.value
                            );
                            var cls = classNames({
                                [style.selected]: isSelected
                            });
                            const item = (
                                //<a aria-label="Consumer/Retail" aria-selected="false" id="industry-search-item-0" role="option" class="dropdown-item" href="#">Consumer/Retail</a>
                                <MenuItem
                                    key={index}
                                    option={i}
                                    position={index}
                                    className={cls}
                                >
                                    <Highlighter search={state.text}>
                                        {i.label}
                                    </Highlighter>
                                </MenuItem>
                            );

                            index += 1;
                            return item;
                        })}
                    </Fragment>
                );
            });

        return <Menu {...menuProps}>{items}</Menu>;
    };

    return (
        <Flex direction="column" className={style.header} justify="start">
            <Flex direction="row" className={style.searchBar}>
                <input
                    type="text"
                    className={style.search}
                    value={store.dealPageNameSearch}
                    onInput={e => {
                        store.dealPageNameSearch = e.currentTarget.value;
                    }}
                    placeholder="Search by name"
                ></input>
                <div className={style.industrySearch}>
                    <Typeahead
                        {...typeahead_props}
                        id="industry-search"
                        className={style.industrySearch}
                        filterBy={filterBy}
                        options={options}
                        placeholder="Search by Industry"
                        onChange={updateSelectionIndustry}
                        selected={multiSelectionsIndustry}
                        multiple={true}
                    ></Typeahead>
                </div>
                <div className={style.scoreSearch}>
                    <Typeahead
                        id="score-search"
                        filterBy={filterByReturnAll}
                        className={style.scoreSearch}
                        options={options_score}
                        placeholder="Search by Score"
                        onChange={updateScoreSeletion}
                        selected={scoreSelection}
                    ></Typeahead>
                </div>
                <UploadDeal />
            </Flex>
        </Flex>
    );
});
