import { observer } from 'mobx-react';
import { withRouter } from 'react-router-class-tools';

import { Header } from '@assets/component/Header/Header';
import { useStore } from '@assets/model/Store';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword
} from 'firebase/auth';
import { getFirebase } from '@assets/firebase/FirebaseAdapater';
import { set } from 'lodash';

const LoginPage = observer(() => {
    const { id } = useParams();
    const store = useStore();

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [resetEmail, setResetEmail] = useState('');
    const [authState, setAuthState] = useState(false);

    useEffect(() => {
        const auth = getAuth(getFirebase().app);
        auth.onAuthStateChanged(user => {
            if (user) {
                setAuthState(true);
            } else {
                setAuthState(false);
            }
        });
    });
    const login = async () => {
        const auth = getAuth(getFirebase().app);
        try {
            const user = await signInWithEmailAndPassword(
                auth,
                loginEmail,
                loginPassword
            );

            if (user) {
                getFirebase().store_audit_entry('LOGIN');
                setTimeout(() => {
                    location.href = '/';
                }, 750);
            }
        } catch (e) {
            alert('Invalid user/pass');
        }
    };

    const sendReset = () => {
        const auth = getAuth(getFirebase().app);
        sendPasswordResetEmail(auth, resetEmail);
        alert('Reset email sent');
    };

    const logout = () => {
        const auth = getAuth(getFirebase().app);
        auth.signOut();
    };

    const loggedIn = (
        <i
            style={{ color: 'green', left: 5, top: 3, position: 'relative' }}
            className="bi bi-check-circle"
        ></i>
    );
    const loggedOut = (
        <i
            style={{ color: 'red', left: 5, top: 3, position: 'relative' }}
            className="bi bi-x-circle"
        ></i>
    );
    return (
        <>
            <Container className="w-100 h-100">
                <Row className="d-flex justify-content-between">
                    <Col>
                        <Header />
                    </Col>
                </Row>
                <Row>
                    <Col style={{ textAlign: 'center', fontSize: 25 }}>
                        Login Status: {authState ? loggedIn : loggedOut}
                    </Col>
                </Row>
                <Row className="pt-5">
                    <Col xs={2}></Col>
                    <Col>
                        <Form>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={e =>
                                        setLoginEmail(e.target.value)
                                    }
                                />

                                <Form.Label className="pt-2">
                                    Password
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="..."
                                    onChange={e =>
                                        setLoginPassword(e.target.value)
                                    }
                                />
                            </Form.Group>
                        </Form>
                        <Button variant="primary" onClick={login}>
                            Login
                        </Button>
                    </Col>
                    <Col xs={1}></Col>
                    <Col>
                        <Form>
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    placeholder="name@example.com"
                                    onChange={e =>
                                        setResetEmail(e.target.value)
                                    }
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={sendReset}>
                                Reset
                            </Button>
                        </Form>
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </Container>
            <Button
                variant="primary"
                onClick={logout}
                style={{ position: 'absolute', right: 0, bottom: 0 }}
            >
                Logout
            </Button>
        </>
    );
});

export default withRouter(LoginPage);
