@import '../../utils/global.module.less';

.taskPageDropZone {
    width: 52px;
    height: 60px;
    margin-left: 20px;

    background-color: transparent !important;
    padding-left: 10px !important;
    border: 2px solid white !important;
    transform: translate(-4px, 1px);
    &:hover {
        color: rgb(126, 126, 126) !important;
    }

    transition: border 0.24s ease-in-out;

    &[class*='dz-drag-hover'] {
        border: 2px dashed #007bff !important;
    }

    *[class*='dz-message'] {
        margin: 0 !important;
    }

    border-radius: 10px;
}

.uploadIcon {
    &::before {
        transform: scale(3.1) translate(2px, 5px);
        pointer-events: none;
    }
}
